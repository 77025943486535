@import '../reset.css';
@import '../custom-animate.css';
@import '../../fonts/general-sans/general-sans.css';

@import '../globals.css';
@import '../typography.css';

html,
body {
  height: 100%;
}

.login--container {
  /* background-image: url("../image/bg-gradient-onboarding.png?as=webp");
  background-size: cover; */
  padding: 70px 24px 134px;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.login--bgGradient {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  /* display: flex; */
  width: 100%;
  height: 100%;
}
.login--card {
  position: relative;
  width: 100%;
  max-width: 384px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.login--topRectangle {
  display: flex;
}
.login--bottomRectangle {
  display: flex;
}
.login--cardInner {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 109px;
}
.login--appLogo {
  display: flex;
  width: 140px;

  margin-bottom: 56px;
}
.login--header {
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-bottom: 32px;
}
.login--headerTitle {
  color: #021624;

  /* Landing Page/Heading/H5 */
  font-family: 'General Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
}
.login--headerDesc {
  color: var(--neutral-60);

  /* Body XSmall/Medium */
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}

.login--form {
  display: flex;
  flex-direction: column;
}
.login--formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.login--formGroup:nth-child(1) {
  margin-bottom: 12px;
}
.login--formGroup:nth-child(2) {
  margin-bottom: 32px;
}
.login--formGroup label {
  color: var(--neutral-60);

  /* Body XSmall/Medium */
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}
.login--formGroup input {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 40px;
  width: 100%;

  border-radius: 6px;
  border: 1px solid var(--neutral-30);

  color: #000;

  /* Body Small/Regular */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.forgetPasswordGroupLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.forgetPasswordLink {
  color: var(--primary-main);
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  text-decoration-line: underline;
}
.login--formAction {
  display: flex;
  gap: 72px;
  align-items: center;
  justify-content: space-between;
}
.login--linkRegister {
  color: var(--neutral-60);

  /* Body XSmall/Medium */
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}
.login--linkRegister a {
  color: var(--primary-main);
  text-decoration-line: underline;
}
.btn.login--btnSubmit {
  display: flex;
  height: 40px;
  padding: 0 16px;
  justify-content: center;
  align-items: center;

  border-radius: 24px;
  border: 1px solid var(--primary-main);
  background: var(--primary-main);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: var(--neutral-10);

  /* Body Small/Bold */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}

.login--footer {
  width: fit-content;
  position: absolute;
  bottom: 24px;
  color: rgba(255, 255, 255, 0.6);

  /* Body Small/Medium */
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

@media (min-width: 992px) {
  .login--cardInner {
    padding: 32px;
  }
  .login--footer {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
}
