* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --animate-delay: .1s !important;
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: calc(var(--animate-delay) * 1);
  animation-delay: calc(var(--animate-delay) * 1);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__delay-6s {
  animation-delay: 6s;
  -webkit-animation-delay: calc(var(--animate-delay) * 6);
  animation-delay: calc(var(--animate-delay) * 6);
}

.animate__animated.animate__delay-7s {
  animation-delay: 7s;
  -webkit-animation-delay: calc(var(--animate-delay) * 7);
  animation-delay: calc(var(--animate-delay) * 7);
}

.animate__animated.animate__delay-8s {
  animation-delay: 8s;
  -webkit-animation-delay: calc(var(--animate-delay) * 8);
  animation-delay: calc(var(--animate-delay) * 8);
}

.animate__animated.animate__delay-9s {
  animation-delay: 9s;
  -webkit-animation-delay: calc(var(--animate-delay) * 9);
  animation-delay: calc(var(--animate-delay) * 9);
}

.animate__animated.animate__delay-10s {
  animation-delay: 10s;
  -webkit-animation-delay: calc(var(--animate-delay) * 10);
  animation-delay: calc(var(--animate-delay) * 10);
}

.animate__animated.animate__delay-11s {
  animation-delay: 11s;
  -webkit-animation-delay: calc(var(--animate-delay) * 11);
  animation-delay: calc(var(--animate-delay) * 11);
}

.animate__animated.animate__delay-12s {
  animation-delay: 12s;
  -webkit-animation-delay: calc(var(--animate-delay) * 12);
  animation-delay: calc(var(--animate-delay) * 12);
}

.animate__animated.animate__delay-13s {
  animation-delay: 13s;
  -webkit-animation-delay: calc(var(--animate-delay) * 13);
  animation-delay: calc(var(--animate-delay) * 13);
}

.animate__animated.animate__delay-14s {
  animation-delay: 14s;
  -webkit-animation-delay: calc(var(--animate-delay) * 14);
  animation-delay: calc(var(--animate-delay) * 14);
}

.animate__animated.animate__delay-15s {
  animation-delay: 15s;
  -webkit-animation-delay: calc(var(--animate-delay) * 15);
  animation-delay: calc(var(--animate-delay) * 15);
}

.animate__animated.animate__delay-16s {
  animation-delay: 16s;
  -webkit-animation-delay: calc(var(--animate-delay) * 16);
  animation-delay: calc(var(--animate-delay) * 16);
}

.animate__animated.animate__delay-17s {
  animation-delay: 17s;
  -webkit-animation-delay: calc(var(--animate-delay) * 17);
  animation-delay: calc(var(--animate-delay) * 17);
}

.animate__animated.animate__delay-18s {
  animation-delay: 18s;
  -webkit-animation-delay: calc(var(--animate-delay) * 18);
  animation-delay: calc(var(--animate-delay) * 18);
}

.animate__animated.animate__delay-19s {
  animation-delay: 19s;
  -webkit-animation-delay: calc(var(--animate-delay) * 19);
  animation-delay: calc(var(--animate-delay) * 19);
}

.animate__animated.animate__delay-20s {
  animation-delay: 20s;
  -webkit-animation-delay: calc(var(--animate-delay) * 20);
  animation-delay: calc(var(--animate-delay) * 20);
}

@keyframes fadeInUpOneTenth {
  0% {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInUpOneTenth {
  animation-name: fadeInUpOneTenth;
}

@font-face {
  font-family: GeneralSans-Variable;
  src: url("GeneralSans-Variable.8f5cd529.woff2") format("woff2"), url("GeneralSans-Variable.9478aac0.woff") format("woff"), url("GeneralSans-Variable.ed56b7f8.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans-VariableItalic;
  src: url("GeneralSans-VariableItalic.ef6a33c8.woff2") format("woff2"), url("GeneralSans-VariableItalic.09904a6c.woff") format("woff"), url("GeneralSans-VariableItalic.392f5d1d.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-Extralight.50a8a19e.woff2") format("woff2"), url("GeneralSans-Extralight.b33ac30d.woff") format("woff"), url("GeneralSans-Extralight.4483b63e.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-ExtralightItalic.c7c8fd12.woff2") format("woff2"), url("GeneralSans-ExtralightItalic.4d5a60d2.woff") format("woff"), url("GeneralSans-ExtralightItalic.c76ae09b.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-Light.629f962e.woff2") format("woff2"), url("GeneralSans-Light.48aa33f3.woff") format("woff"), url("GeneralSans-Light.39a34edc.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-LightItalic.95b5c0e4.woff2") format("woff2"), url("GeneralSans-LightItalic.1133fcad.woff") format("woff"), url("GeneralSans-LightItalic.601f41bf.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-Regular.7611025e.woff2") format("woff2"), url("GeneralSans-Regular.d652dae4.woff") format("woff"), url("GeneralSans-Regular.62489a53.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-Italic.1e0b3f79.woff2") format("woff2"), url("GeneralSans-Italic.edbacd03.woff") format("woff"), url("GeneralSans-Italic.c2141b69.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-Medium.385d10ef.woff2") format("woff2"), url("GeneralSans-Medium.3db2032c.woff") format("woff"), url("GeneralSans-Medium.496b905a.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-MediumItalic.14022b0a.woff2") format("woff2"), url("GeneralSans-MediumItalic.ad045d41.woff") format("woff"), url("GeneralSans-MediumItalic.4ff02de8.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-Semibold.c9b42dc5.woff2") format("woff2"), url("GeneralSans-Semibold.bf4c5428.woff") format("woff"), url("GeneralSans-Semibold.24ac9ba4.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-SemiboldItalic.f6755eb9.woff2") format("woff2"), url("GeneralSans-SemiboldItalic.9d89cbcd.woff") format("woff"), url("GeneralSans-SemiboldItalic.5c5b0310.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-Bold.bc1eb702.woff2") format("woff2"), url("GeneralSans-Bold.6afbfc5d.woff") format("woff"), url("GeneralSans-Bold.75d1d4cc.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: General Sans;
  src: url("GeneralSans-BoldItalic.2d765d8f.woff2") format("woff2"), url("GeneralSans-BoldItalic.ff39d938.woff") format("woff"), url("GeneralSans-BoldItalic.a0abe114.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

:root {
  --primary-main: #05a6f0;
  --primary-surface: #cdedfc80;
  --primary-border: #ace1fa;
  --primary-hover: #048ac8;
  --primary-pressed: #025378;
  --primary-focus: #cdedfc;
  --neutral-10: #fff;
  --neutral-20: #f7f9fb;
  --neutral-30: #e2e8f0;
  --neutral-40: #cbd5e1;
  --neutral-50: #94a3b8;
  --neutral-60: #6b7c94;
  --neutral-70: #475569;
  --neutral-80: #334155;
  --neutral-90: #1e293b;
  --neutral-100: #1e2432;
  --danger-100: #fff5f4;
  --danger-200: #f88c8c;
  --danger-300: #f56666;
  --danger-400: #f34040;
  --danger-500: #d92d20;
  --info-100: #f0f8ff;
  --info-200: #89b3f7;
  --info-300: #6299f5;
  --info-400: #3b80f2;
  --info-500: #1570ef;
  --success-100: #e6ffee;
  --success-200: #77df9e;
  --success-300: #4ad57d;
  --success-400: #1dca5d;
  --success-500: #039855;
  --warning-100: #fff7de;
  --warning-200: #f4d36b;
  --warning-300: #fec84b;
  --warning-400: #f3a515;
  --warning-500: #dc6803;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.img {
  width: 100%;
  height: 100%;
}

.btn.btn-talk-us {
  border: 1px solid var(--Neutral-30, #e2e8f0);
  color: var(--Primary-Main, #05a6f0);
  border-radius: 46px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding: 0 16px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  display: flex;
}

.mobile {
  display: flex;
}

.tablet, .desktop {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-48 {
  right: 48px;
}

.d-flex {
  display: flex;
}

.d-inline {
  display: inline;
}

.d-block {
  display: block;
}

.object-cover {
  object-fit: cover;
}

.object-left {
  object-position: left;
}

.overflow-hidden {
  overflow: hidden;
}

.preHeadingText {
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.font-montserrat {
  font-family: Montserrat, sans-serif;
}

.font-general-sans {
  font-family: General Sans, sans-serif;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-neutral-10 {
  color: var(--neutral-10);
}

.text-neutral-40 {
  color: var(--neutral-40);
}

.text-neutral-50 {
  color: var(--neutral-50);
}

.text-neutral-60 {
  color: var(--neutral-60);
}

.text-neutral-100 {
  color: var(--neutral-100);
}

.text-success-400 {
  color: var(--success-400);
}

.text-primary-main {
  color: var(--primary-main);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.p-24 {
  padding: 24px;
}

.p-32 {
  padding: 32px;
}

.p-40 {
  padding: 40px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-22 {
  margin-bottom: 22px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-64 {
  margin-bottom: 64px;
}

.-mr-24 {
  margin-right: -16px;
}

.bg-neutral-10 {
  background: var(--neutral-10);
}

.bg-neutral-30 {
  background: var(--neutral-30);
}

.bg-neutral-100 {
  background: var(--neutral-100);
}

.bg-warning-400 {
  background: var(--warning-400);
}

.bg-primary-main {
  background: var(--primary-main);
}

.rounded-24 {
  border-radius: 24px;
}

.rounded-full {
  border-radius: 1000px;
}

.col-gap-24 {
  column-gap: 24px;
}

.row-gap-24 {
  row-gap: 24px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-8 {
  gap: 8px;
}

.gap-80 {
  gap: 80px;
}

.flex-none {
  flex: none;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reserve {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.items-stretch {
  align-items: stretch;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.items-end {
  align-items: end;
}

.z-1 {
  z-index: 1;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.max-w-40 {
  max-width: 40px;
}

.max-w-48 {
  max-width: 48px;
}

.max-w-108 {
  max-width: 108px;
}

.max-w-174 {
  max-width: 174px;
}

.max-w-209 {
  max-width: 209px;
}

.max-w-225 {
  max-width: 225px;
}

.max-w-243 {
  max-width: 243px;
}

.max-w-260 {
  max-width: 260px;
}

.max-w-277 {
  max-width: 277px;
}

.max-w-282 {
  max-width: 282px;
}

.max-w-311 {
  max-width: 311px;
}

.max-w-384 {
  max-width: 384px;
}

.max-w-486 {
  max-width: 486px;
}

.max-w-386 {
  max-width: 386px;
}

.max-w-744 {
  max-width: 744px;
}

.max-w-792 {
  max-width: 792px;
}

.min-h-97 {
  min-height: 97px;
}

.max-h-48 {
  max-height: 48px;
}

.max-h-97 {
  max-height: 97px;
}

.max-h-101 {
  max-height: 101px;
}

.min-width-384 {
  min-width: 384px;
}

.w-8 {
  width: 8px;
}

.h-8 {
  height: 8px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.pointer {
  cursor: pointer;
}

@media (min-width: 992px) {
  .md-d-block {
    display: block;
  }

  .md-max-w-auto {
    max-width: none;
  }

  .md-max-w-174 {
    max-width: 174px;
  }

  .md-max-w-266 {
    max-width: 266px;
  }

  .md-max-w-295 {
    max-width: 295px;
  }

  .md-max-w-374 {
    max-width: 374px;
  }

  .md-min-w-174 {
    min-width: 174px;
  }

  .md-max-h-auto {
    max-height: none;
  }

  .md-gap-18 {
    gap: 18px;
  }

  .md-flex-row {
    flex-direction: row;
  }

  .md-items-end {
    align-items: end;
  }

  .md-mb-32 {
    margin-bottom: 32px;
  }

  .mobile {
    display: none;
  }

  .tablet {
    display: flex;
  }

  .desktop {
    display: none;
  }
}

@media (min-width: 1200px) {
  .lg-gap-80 {
    gap: 80px;
  }

  .lg-max-w-486 {
    max-width: 486px;
  }

  .mobile, .tablet {
    display: none;
  }

  .desktop {
    display: flex;
  }
}

.policy-none {
  display: none !important;
}

.text-h4 {
  font-size: 32px;
  font-style: normal;
  line-height: 150%;
}

.text-h5 {
  font-size: 24px;
  font-style: normal;
  line-height: 150%;
}

.text-h6 {
  font-size: 20px;
  font-style: normal;
  line-height: 150%;
}

.text-body-xsmall {
  font-size: 12px;
  font-style: normal;
  line-height: 150%;
}

.text-body-small {
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
}

.text-body-medium {
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
}

.text-body-large {
  font-size: 18px;
  font-style: normal;
  line-height: 150%;
}

@media (min-width: 1200px) {
  .lg-text-h6 {
    font-size: 20px;
    font-style: normal;
    line-height: 150%;
  }
}

html, body {
  height: 100%;
}

.login--container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 70px 24px 134px;
  display: flex;
  overflow-y: scroll;
}

.login--bgGradient {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.login--card {
  flex-direction: column;
  width: 100%;
  max-width: 384px;
  margin: auto;
  display: flex;
  position: relative;
}

.login--topRectangle, .login--bottomRectangle {
  display: flex;
}

.login--cardInner {
  background: #fff;
  flex-direction: column;
  padding: 32px 32px 109px;
  display: flex;
}

.login--appLogo {
  width: 140px;
  margin-bottom: 56px;
  display: flex;
}

.login--header {
  flex-direction: column;
  gap: 4px;
  margin-bottom: 32px;
  display: flex;
}

.login--headerTitle {
  color: #021624;
  font-family: General Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.login--headerDesc {
  color: var(--neutral-60);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.login--form {
  flex-direction: column;
  display: flex;
}

.login--formGroup {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.login--formGroup:first-child {
  margin-bottom: 12px;
}

.login--formGroup:nth-child(2) {
  margin-bottom: 32px;
}

.login--formGroup label {
  color: var(--neutral-60);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.login--formGroup input {
  border: 1px solid var(--neutral-30);
  color: #000;
  border-radius: 6px;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.forgetPasswordGroupLabel {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.forgetPasswordLink {
  color: var(--primary-main);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration-line: underline;
}

.login--formAction {
  justify-content: space-between;
  align-items: center;
  gap: 72px;
  display: flex;
}

.login--linkRegister {
  color: var(--neutral-60);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.login--linkRegister a {
  color: var(--primary-main);
  text-decoration-line: underline;
}

.btn.login--btnSubmit {
  border: 1px solid var(--primary-main);
  background: var(--primary-main);
  color: var(--neutral-10);
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  display: flex;
  box-shadow: 0 1px 2px #1018280d;
}

.login--footer {
  color: #fff9;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  position: absolute;
  bottom: 24px;
}

@media (min-width: 992px) {
  .login--cardInner {
    padding: 32px;
  }

  .login--footer {
    font-size: 14px;
  }
}

/*# sourceMappingURL=login.ca1eab5a.css.map */
